// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-concept-map-js": () => import("./../../../src/pages/conceptMap.js" /* webpackChunkName: "component---src-pages-concept-map-js" */),
  "component---src-pages-concept-matrix-js": () => import("./../../../src/pages/conceptMatrix.js" /* webpackChunkName: "component---src-pages-concept-matrix-js" */),
  "component---src-pages-concepts-js": () => import("./../../../src/pages/concepts.js" /* webpackChunkName: "component---src-pages-concepts-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notional-machines-js": () => import("./../../../src/pages/notionalMachines.js" /* webpackChunkName: "component---src-pages-notional-machines-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-overview-notional-machines-js": () => import("./../../../src/pages/overview/notionalMachines.js" /* webpackChunkName: "component---src-pages-overview-notional-machines-js" */),
  "component---src-pages-overview-references-js": () => import("./../../../src/pages/overview/references.js" /* webpackChunkName: "component---src-pages-overview-references-js" */),
  "component---src-pages-overview-refutations-js": () => import("./../../../src/pages/overview/refutations.js" /* webpackChunkName: "component---src-pages-overview-refutations-js" */),
  "component---src-pages-overview-symptoms-js": () => import("./../../../src/pages/overview/symptoms.js" /* webpackChunkName: "component---src-pages-overview-symptoms-js" */),
  "component---src-pages-overview-textbooks-js": () => import("./../../../src/pages/overview/textbooks.js" /* webpackChunkName: "component---src-pages-overview-textbooks-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-related-work-js": () => import("./../../../src/pages/relatedWork.js" /* webpackChunkName: "component---src-pages-related-work-js" */),
  "component---src-pages-self-check-js": () => import("./../../../src/pages/selfCheck.js" /* webpackChunkName: "component---src-pages-self-check-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-specs-js": () => import("./../../../src/pages/specs.js" /* webpackChunkName: "component---src-pages-specs-js" */),
  "component---src-pages-textbooks-js": () => import("./../../../src/pages/textbooks.js" /* webpackChunkName: "component---src-pages-textbooks-js" */),
  "component---src-templates-concept-js": () => import("./../../../src/templates/concept.js" /* webpackChunkName: "component---src-templates-concept-js" */),
  "component---src-templates-contributor-js": () => import("./../../../src/templates/contributor.js" /* webpackChunkName: "component---src-templates-contributor-js" */),
  "component---src-templates-misconception-iframe-js": () => import("./../../../src/templates/misconception-iframe.js" /* webpackChunkName: "component---src-templates-misconception-iframe-js" */),
  "component---src-templates-misconception-js": () => import("./../../../src/templates/misconception.js" /* webpackChunkName: "component---src-templates-misconception-js" */),
  "component---src-templates-misconceptions-by-pl-js": () => import("./../../../src/templates/misconceptionsByPl.js" /* webpackChunkName: "component---src-templates-misconceptions-by-pl-js" */),
  "component---src-templates-notional-machine-js": () => import("./../../../src/templates/notionalMachine.js" /* webpackChunkName: "component---src-templates-notional-machine-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-specification-js": () => import("./../../../src/templates/specification.js" /* webpackChunkName: "component---src-templates-specification-js" */),
  "component---src-templates-specification-section-js": () => import("./../../../src/templates/specificationSection.js" /* webpackChunkName: "component---src-templates-specification-section-js" */),
  "component---src-templates-textbook-js": () => import("./../../../src/templates/textbook.js" /* webpackChunkName: "component---src-templates-textbook-js" */),
  "component---src-templates-textbook-section-js": () => import("./../../../src/templates/textbookSection.js" /* webpackChunkName: "component---src-templates-textbook-section-js" */)
}

