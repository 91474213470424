module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md"],"gatsbyRemarkPlugins":["gatsby-remark-smartypants","gatsby-plugin-catch-links","gatsby-remark-graphviz",{"resolve":"gatsby-remark-mermaid","options":{"language":"mermaid","theme":"forest","viewport":{"width":200,"height":200},"mermaidOptions":{"themeCSS":".edgeLabel { background: #fdf6e3; }","sequence":{"actorMargin":10,"mirrorActors":false}}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":680}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":false,"noInlineHighlight":false,"languageExtensions":[{"language":"superscript","extend":"javascript","definition":{"superscript_types":{}},"insertBefore":{"function":{"superscript_keywords":{}}}}],"prompt":{"user":"root","host":"localhost","global":false},"escapeEntities":{}}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"programming-misconceptions","short_name":"progmiscon","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/progmiscon-logo-64.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b9d1225d62427eaed403867270694077"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"progmiscon.org","customDomain":"analytics.progmiscon.org/js/plausible.js?original="},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
